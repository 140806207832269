html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.link {
  color: #4169e1;
  cursor: pointer;
  text-decoration: none;
}

.banner-container {
  position: relative;
}

.banner-container img {
  width: 100%;
}

.dark-33 {
  filter: brightness(66%);
}

.dark-50 {
  filter: brightness(50%);
}

.dark-66 {
  filter: brightness(33%);
}

.dark-75 {
  filter: brightness(25%);
}

.fill {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#footer {
  z-index: 1000;
  position: relative;
}

.glow-button {
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  transition: box-shadow .3s;
}

.glow-button:hover {
  cursor: pointer;
  box-shadow: 0 0 20px 5px #b73333;
}

.hover:hover {
  cursor: pointer;
  transform: translateY(-10px);
}

#interface {
  z-index: 1;
  position: relative;
}

#interface .row {
  justify-content: center;
  display: flex;
}

.list-group.list-group-flush .list-group-item.bg-dark {
  border-color: red;
}

.list-group.list-group-flush .list-group-item.bg-danger {
  border-color: #fff;
}

.menu-icon {
  height: 24px;
}

.overlay-text {
  color: #fff;
  font-size: 25px;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cross-mark {
  color: red;
  font-size: 24px;
}

.check-mark {
  color: green;
  font-size: 24px;
}

.icon-15 {
  border-radius: 15%;
}

.rectangle-container {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  overflow: hidden;
}

.square-container-normal, .square-container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.square-container .fill {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.square-container img, .list-description {
  transition: opacity .3s;
}

.list-description {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pdf-viewer {
  width: 98vw;
  height: 100vh;
}

.square-container:hover .list-description {
  opacity: 1;
  pointer-events: auto;
}

.square-container:hover img {
  filter: brightness(33%);
}

.rounded-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded-top-left {
  border-top-left-radius: 10px;
}

.rounded-bottom-left {
  border-bottom-left-radius: 10px;
}

.rounded-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rounded-top-right {
  border-top-right-radius: 10px;
}

.rounded-bottom-right {
  border-bottom-right-radius: 10px;
}

@media only screen and (width <= 1024px) {
  .profile-header {
    font-size: 18px;
  }

  .text-1, .text-2 {
    font-size: 14px;
  }
}

@media only screen and (width <= 480px) {
  .profile-header {
    font-size: 16px;
  }

  .text-1, .text-2 {
    font-size: 12px;
  }
}

/*# sourceMappingURL=budget_request.3f73b576.css.map */
